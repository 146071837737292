import React from "react"
import { Link } from "gatsby"
import { FormattedMessage } from "react-intl"

const Topics = ({ data }) => {
  const { edges } = data.allMarkdownRemark
  return (
    <section className="section">
      <div className="container">
        <h1 className="title has-text-centered">
          <FormattedMessage id="topics-title" />
        </h1>
        <div className="topic-cards">
          {edges.map((edge, index) => {
            const { frontmatter } = edge.node
            const { slug, name, info, icon } = frontmatter
            const images = require.context("../images", true)
            const image = images(`./icons/${icon}@2x.png`)
            return (
              <Link key={index} className="topic-card" to={slug}>
                <div className="card-left">
                  <img src={image} alt="{name}" />
                </div>
                <div className="card-right">
                  <h2 className="card-name">{name}</h2>
                  <p className="card-info">{info}</p>
                </div>
              </Link>
            )
          })}
        </div>
      </div>
    </section>
  )
}

export default Topics
