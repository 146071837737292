import React from "react"
import { graphql } from "gatsby"
import Layout from "../layouts/zh"
import SEO from "../layouts/seo"
import Topics from "../components/topics"

const TopicsPage = ({ data, location }) => {
  return (
    <Layout location={location} pageType="topics">
      <SEO title="话题" lang="zh" />
      <Topics data={data}></Topics>
    </Layout>
  )
}

export default TopicsPage

export const pageQuery = graphql`
  query {
    allMarkdownRemark(
      sort: { order: ASC, fields: [frontmatter___sort] }
      filter: {
        frontmatter: {
          type: { in: ["topic"] }
          langKey: { in: ["zh"] }
          hidden: { ne: true }
        }
      }
    ) {
      edges {
        node {
          frontmatter {
            id
            slug
            name
            info
            icon
          }
        }
      }
    }
  }
`
